import React from 'react';
import './SectionCombos.css';

// Importe as imagens PNG
import incluso from '../assets/streaming/incluso.png';
import incluso2 from '../assets/streaming/incluso2.png';
import beneficios from '../assets/streaming/beneficios.png';
import doisbeneficios from '../assets/streaming/doisbeneficios.png';

const combos = [
    {
        name: "START",
        speed: "650MB",
        streaming: [incluso],
        price: "129"
    },
    {
        name: "TURBO",
        speed: "750MB",
        streaming: [incluso2, beneficios],
        price: "159"
    },
    {
        name: "ULTRA",
        speed: "1GB",
        streaming: [incluso2, doisbeneficios],
        price: "189"
    },
    // Adicione mais planos conforme necessário
];

function SectionCombos() {
    return (
        <div className="section-combos-boxes" id="combos">
            <p className="section-title">Combos <strong>COMPLETOS</strong></p>
            <p className="section-subtitle">Pacotes pensados para combinar velocidade e entretenimento</p>

            <div className="column-wrapper">
                <div className="left-column">
                    {/* Conteúdo da coluna esquerda */}
                </div>
                <div className="right-column">
                    <table>
                        <tbody>
                            {combos.map((combo, index) => (
                                <React.Fragment key={index}>
                                    <tr key={index}>
                                        <td><span className="combospeed">{combo.speed}</span></td>
                                        <td className="plus"> + </td>
                                        <td>
                                            <div className="streaming-logos">
                                                {combo.streaming.map((logo, logoIndex) => (
                                                    <img key={logoIndex} src={logo} alt={`Logo ${logo}`} />
                                                ))}
                                            </div>
                                        </td>
                                        <td><span className="porapenas">por apenas</span>
                                            <br /><span className="cifrao">R$</span><span className="price">{combo.price}</span><span className="centavos">,90</span></td>
                                        <td><button className="list-button">Quero esse</button></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <hr className="linha-separadora" />
                                        </td>
                                    </tr>
                                </React.Fragment>
                                
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
}

export default SectionCombos;
